/* eslint-disable no-alert */
const activeEnvironments = ['staging', 'production'];
const environment = process.env.ENV ? process.env.ENV.toLowerCase() : '';

const validateHostedDomain = () => {
  if (
    typeof window === 'undefined'
    || !activeEnvironments.includes(environment)
    || process.env.GATSBY_ACTIVE_ENV === 'testing'
  ) return;

  if (window.location.hostname === process.env.HOSTNAME) return;

  document.body.innerHTML = '';
  setTimeout(() => alert(`Please visit the original website at ${process.env.HOSTNAME}`), 500);
};

export default validateHostedDomain;
